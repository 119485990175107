import { Routes } from "@angular/router";
import { AuthGuard } from "../../shared/authguards/auth.guard";
import { PremiumGuard } from "../../shared/authguards/premium.guard";

export const PREMIUM_ROUTES: Routes = [
  {
    path: "premium-services",
    loadComponent: () => import("./premium.component").then(m => m.PremiumComponent),
    data: { title: "pageTitles.premiumPage" },
    canActivate: [AuthGuard, PremiumGuard],
    children: [],
  },
];
