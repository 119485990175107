import { AsyncPipe, Location, NgClass, NgIf, UpperCasePipe } from "@angular/common";
import { Component, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { HeaderInfoService } from "./header-info.service";
import { NotificationService } from "../message/notification.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AccessibilityService } from "../../shared/accessibility/accessibility.service";
import { QuestionnaireService } from "../../services/questionnaire.service";
import { AuthService } from "../../services/auth.service";
import { UserInfoService } from "../../services/user-info.service";
import { DeviceDetectService } from "../../services/device-detect.service";
import { TranslationService } from "../../services/translation-service";
import { NavigateToMessagesService } from "../../services/navigate-to-messages.service";
import { HeaderInfo } from "../../models/header-info.model";
import { AppMessage } from "../../models/app-message.model";
import { NgbProgressbar } from "@ng-bootstrap/ng-bootstrap";
import { MobileMenuComponent } from "./mobile-menu/mobile-menu.component";
import { ClickOutsideDirective } from "../../directives/click-outside.directive";
import { UrlService } from "../../services/url.service";

@Component({
    selector: "app-global-header",
    templateUrl: "./global-header.component.html",
    styleUrls: ["./global-header.component.css"],
    imports: [
        NgIf,
        MobileMenuComponent,
        ClickOutsideDirective,
        NgClass,
        RouterLink,
        NgbProgressbar,
        TranslateModule,
        AsyncPipe,
        UpperCasePipe,
    ]
})
export class GlobalHeaderComponent implements OnInit, OnDestroy {
  @Input() headerInfo: HeaderInfo = {
    isBackground: true,
    title: "",
    isLeftIcon: false,
    leftIconName: "",
    leftIconRouterLink: "/",
    isPatientNameDisplayed: false,
    isInfoText: false,
    infoText: "",
  };
  mobileMenuOpen = false;
  patientName: string;
  shrinkHeader: boolean;
  isLoggedIn$: Observable<boolean>;
  isNewUser: boolean;
  headerShrink$: Observable<boolean>;
  areNewMessages: boolean;
  messagesList: AppMessage[];
  notificationsChangeSub: Subscription;
  overallProgressBarValue;
  sub: Subscription;
  sub1: Subscription;
  showProgressBar = false;
  isMobile: boolean;
  currentLanguage: string;
  mainMenuPageSub: Subscription;
  isMainPage = false;

  constructor(
    private headerInfoService: HeaderInfoService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private userInfoService: UserInfoService,
    private router: Router,
    private location: Location,
    private questionnaireService: QuestionnaireService,
    private deviceDetect: DeviceDetectService,
    private translate: TranslateService,
    private translationService: TranslationService,
    private navigateToMessages: NavigateToMessagesService,
    public accessibility: AccessibilityService,
    private urlService: UrlService,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn();
    this.isMobile = this.deviceDetect.isMobile;

  }

  ngOnInit() {
    this.subscribeToHeaderInfo();
    this.subscribeToUserData();
    this.subscribeToHeaderShrink();
    this.subscribeToNotificationsChanged();
    this.animateHeader();
    this.authService.isNew.subscribe((isNew) => {
      this.isNewUser = isNew;
    });
    this.sub1 = this.questionnaireService.motivationQuestionnaireView.subscribe(
      (value) => (this.showProgressBar = value),
    );
    this.sub = this.questionnaireService.overallProgressBarValue.subscribe(
      (value) => (this.overallProgressBarValue = value),
    );
    this.currentLanguage = this.translate.currentLang;

    this.mainMenuPageSub = this.urlService.currentUrl$.subscribe((url) => {
      this.isMainPage = url === "/" || url === "/premium-services";

    });
  }

  openMenu() {
    const currentUrl = this.router.url;
    const parts = currentUrl.split("/");
    if (!this.authService.isAuthenticated.value) {
      this.router.navigateByUrl("welcome/menu");
    } else this.openMobileMenu();
  }

  openMobileMenu() {
    setTimeout(() => {
      this.mobileMenuOpen = true;
    }, 50);
  }

  closeMenu() {
    this.mobileMenuOpen = false;
    this.hideProgressBar();
  }


  subscribeToHeaderInfo() {
    this.headerInfoService.headerInfo.subscribe((updatedHeaderInfo) => {
      this.headerInfo = updatedHeaderInfo;
      this.shrinkHeader = false;
    });
  }

  subscribeToUserData() {
    this.userInfoService.sessionContext.subscribe((sessionContext) => {
      if (sessionContext !== null) {
        this.patientName =
          sessionContext.principal.firstName.toLowerCase() +
          " " +
          sessionContext.principal.lastName.toLowerCase();
      } else {
        this.patientName = "";
      }
    });
  }

  subscribeToHeaderShrink() {
    this.headerShrink$ = this.headerInfoService.needToShrinkHeader();
    this.headerShrink$.subscribe((value) => {
      this.shrinkHeader = value;
    });
  }

  subscribeToNotificationsChanged() {
    this.notificationsChangeSub = this.notificationService
      .getNotifications()
      .subscribe((messages: AppMessage[]) => {
        this.messagesList = messages;
        this.checkIfHasNewMessages();
      });
  }

  checkIfHasNewMessages() {
    this.resetUnreadDisplay();
    for (const message of this.messagesList) {
      if (message.isUnread && message.directionToPatient) {
        this.areNewMessages = true;
        break;
      }
    }
  }

  useLanguage(lang: string) {
    this.translate.use(lang);
    this.currentLanguage = lang;
    this.translationService.setLanguage(lang);
  }

  resetUnreadDisplay() {
    this.areNewMessages = false;
  }

  animateHeader() {
    window.onscroll = () => {
      this.headerInfoService.scrollOffset = window.pageYOffset;
      this.shrinkHeader =
        window.pageYOffset > 50 || this.router.url.endsWith("message");
    };
  }

  clickOnTitle() {
    if (this.router.url.endsWith("message")) {
      this.location.back();
    } else {
      this.router.navigate(["/"]);
    }
  }

  hideProgressBar() {
    this.showProgressBar = false;
    this.overallProgressBarValue = 0;
  }

  clickOnMessagesIcon() {
    this.hideProgressBar();
    this.navigateToMessages.navigateToMessages();
  }

  clickOnLeftIcon() {
    this.showProgressBar = false;
    this.overallProgressBarValue = 0;
  }

  onLogout() {
    this.authService.clearSession();
  }

  @HostListener("window:popstate")
  onPopState() {
    this.hideProgressBar();
  }

  ngOnDestroy() {
    this.notificationsChangeSub.unsubscribe();
    this.userInfoService.sessionContext.unsubscribe();
    this.sub.unsubscribe();
    this.sub1.unsubscribe();
    this.mainMenuPageSub.unsubscribe();
  }
}
