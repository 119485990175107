import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class PremiumGuard implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate(): boolean {
    const hasPaidService: boolean = JSON.parse(sessionStorage.getItem("HAS_PAID_SERVICE")); //true if institution has paid services
    const hasRedirect: boolean = JSON.parse(sessionStorage.getItem("redirectToPremiumAfterLogin")); //true if coming from advertisement link
    const premiumEnabled: boolean = environment.premium.enabled && (hasPaidService || hasRedirect);

    if (!premiumEnabled) {
      this.router.navigateByUrl("/"); // Redirect if access is denied
      return false;
    }
    return true;
  }
}
