import {enableProdMode, importProvidersFrom, Injectable} from "@angular/core";
import {environment} from "./environments/environment";
import "hammerjs";
import {NGX_ECHARTS_CONFIG, NgxEchartsModule} from 'ngx-echarts';
import * as echarts from 'echarts/core';
import {BarChart, GaugeChart, LineChart} from 'echarts/charts';
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';

import {trustedResourceUrl, unwrapResourceUrl} from "safevalues";
import {AppComponent} from "./app/app.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ServiceWorkerModule} from "@angular/service-worker";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {provideAnimations} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AppRoutingModule} from "./app/app-routing.module";
import {RouterModule} from "@angular/router";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatExpansionModule} from "@angular/material/expansion";
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {WebpackTranslateLoader} from "src/webpack-translate-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {NgIdleKeepaliveModule} from "@ng-idle/keepalive";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";
import {CUSTOM_DATE_FORMAT} from "./app/shared/custom-date-format";
import {MomentDateAdapter, MomentDateModule} from "@angular/material-moment-adapter";
import {LeaveFormGuard} from "./app/shared/authguards/leave-form.guard";
import {UrlService} from "./app/services/url.service";
import {CookieService} from "ngx-cookie-service";
import {DatePipe} from "@angular/common";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {
  bootstrapApplication,
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from "@angular/platform-browser";
import {IconService} from "./app/services/icon.service";
import {PageTitleService} from "./app/services/page-title.service";
import Hammer from "hammerjs";
import {MomentModule} from "ngx-moment";
import {UniquePipe} from "./app/features/prescriptions/prescription-list/unique.pipe";
import "moment/locale/et";
import {SessionContextInterceptor} from "./app/shared/interceptors/session-context.interceptor";
import {SendingProgressInterceptor} from "./app/shared/interceptors/sending-progress.interceptor";
import {AuthInterceptor} from "./app/shared/interceptors/auth.interceptor";
import {RequestBlockingInterceptor} from "./app/shared/interceptors/request-blocking-interceptor";

const ngswWorkerScriptUrl = trustedResourceUrl`ngsw-worker.js`;
const unwrappedScriptUrl = unwrapResourceUrl(ngswWorkerScriptUrl) as string;

const isMobile = /android|mobile|samsungbrowser|ipod|mac os|crios|webos|iphone|ipad|blackberry|iemobile|opera mini/.test(
  window.navigator.userAgent.toLowerCase(),
);
const hasTouchPoints = navigator.maxTouchPoints > 0;
const redirectEnabled = environment.desktopRedirect.isRedirect;
if (!hasTouchPoints && !isMobile && redirectEnabled) {
  window.location.href = environment.desktopRedirect.isBaseUrl ? environment.backend.baseUrl + environment.desktopRedirect.url : environment.desktopRedirect.url;
}

@Injectable()
class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    "swipe": {direction: Hammer.DIRECTION_HORIZONTAL},
    "pinch": {enable: false},
    "rotate": {enable: false},
    "pan": {direction: Hammer.DIRECTION_HORIZONTAL},
  };
}


if (environment.production) {
  enableProdMode();
}

// Define allowed log methods based on environment settings
const allowedLogMethods = new Set(environment.logLevel);

// Overwrite log methods that are not allowed
["log", "info", "debug", "warn", "error"].forEach((methodName) => {
  if (!allowedLogMethods.has(methodName)) {
    console[methodName] = () => {
    };
  }
});

echarts.use([
  BarChart,
  CanvasRenderer,
  GaugeChart,
  GridComponent,
  LineChart,
  LegendComponent,
  TooltipComponent,
  TitleComponent,
  ToolboxComponent,
  VisualMapComponent,
  DataZoomComponent
]);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      NgIdleKeepaliveModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: WebpackTranslateLoader,
          deps: [HttpClient],
        },
      }),
      MatExpansionModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MomentDateModule,
      MatFormFieldModule,
      MatInputModule,
      MatIconModule,
      MatBottomSheetModule,
      RouterModule,
      BrowserModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      NgxSpinnerModule,
      MatProgressBarModule,
      HammerModule,
      ServiceWorkerModule.register(unwrappedScriptUrl, {enabled: environment.serviceWorker}),
      NgbModule,
      MomentModule,
      NgxEchartsModule.forRoot({echarts}),
    ),
    {provide: NGX_ECHARTS_CONFIG, useValue: {echarts}},
    {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: "fill"}},
    {provide: MAT_DATE_LOCALE, useValue: "et"},
    DatePipe,
    CookieService,
    UrlService,
    IconService,
    TranslateService,
    PageTitleService,
    LeaveFormGuard,
    UniquePipe,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT},
    NgxSpinnerService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {provide: HTTP_INTERCEPTORS, useClass: SessionContextInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SendingProgressInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: RequestBlockingInterceptor, multi: true},
  ],
})

  .then(() => {
    if ("serviceWorker" in navigator && environment.serviceWorker) {
      console.log("SW registered");
      navigator.serviceWorker.register(unwrappedScriptUrl);

      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.update();
        }
      });
    } else {
      console.log("SW not registered");
    }
  })
  .catch((err) => console.log(err));
