/**
 * @license
 * Copyright Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */
/** @fileoverview Internal implementations of TrustedResourceUrl. */
import { getPolicy } from './trusted_types.js';
import '../environment/dev.js';
import { ensureTokenIsValid, secretToken } from './secrets.js';
/**
 * String that is safe to use in all URL contexts in DOM APIs and HTML
 * documents; even as a reference to resources that may load in the current
 * origin (e.g. scripts and stylesheets).
 *
 * @final
 */
export class TrustedResourceUrl {
  constructor(token, value) {
    if (process.env.NODE_ENV !== 'production') {
      ensureTokenIsValid(token);
    }
    this.privateDoNotAccessOrElseWrappedResourceUrl = value;
  }
  toString() {
    // String coercion minimizes code size.
    // tslint:disable-next-line:restrict-plus-operands
    return this.privateDoNotAccessOrElseWrappedResourceUrl + '';
  }
}
const ResourceUrlImpl = TrustedResourceUrl;
function constructResourceUrl(value) {
  return new ResourceUrlImpl(secretToken, value);
}
/**
 * Builds a new `TrustedResourceUrl` from the given string, without enforcing
 * safety guarantees. It may cause side effects by creating a Trusted Types
 * policy. This shouldn't be exposed to application developers, and must only be
 * used as a step towards safe builders or safe constants.
 */
export function createResourceUrlInternal(value) {
  // Inlining this variable can cause large codesize increases when it is a
  // large constant string. See sizetests/examples/constants for an example.
  /** @noinline */
  const noinlineValue = value;
  const policy = getPolicy();
  return constructResourceUrl(policy ? policy.createScriptURL(noinlineValue) : noinlineValue);
}
/** Checks if the given value is a `TrustedResourceUrl` instance */
export function isResourceUrl(value) {
  return value instanceof TrustedResourceUrl;
}
/**
 * Returns the value of the passed `TrustedResourceUrl` object while ensuring it
 * has the correct type.
 * Using this function directly is not common. Safe types are not meant to be
 * unwrapped, but rather passed to other APIs that consume them, like the DOM
 * wrappers in safevalues/dom.
 *
 * Returns a native `TrustedScriptURL` instance typed as {toString(): string} or a string if Trusted Types are disabled.
 */
export function unwrapResourceUrl(value) {
  if (isResourceUrl(value)) {
    return value.privateDoNotAccessOrElseWrappedResourceUrl;
  } else {
    let message = '';
    if (process.env.NODE_ENV !== 'production') {
      message = 'Unexpected type when unwrapping TrustedResourceUrl';
    }
    throw new Error(message);
  }
}