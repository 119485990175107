import { Component } from "@angular/core";
import { AccessibilityService } from "../accessibility/accessibility.service";
import { DeviceDetectService } from "../../services/device-detect.service";
import { TranslateModule } from "@ngx-translate/core";
import { SkipLinkDirective } from "../../directives/skip-link.directive";

@Component({
    selector: "app-skip-link",
    templateUrl: "./skip-link.component.html",
    styleUrls: ["./skip-link.component.css"],
    imports: [SkipLinkDirective, TranslateModule]
})
export class SkipLinkComponent {

  isMobile: boolean;

  constructor(
    private accessibility: AccessibilityService,
    private deviceDetectService: DeviceDetectService,
  ) {
    this.isMobile = this.deviceDetectService.isMobile;
  }

  onSkipLinkFocus() {
    this.accessibility.updateShowSkipLink(true);
  }

  onSkipLinkFocusOut() {
    this.accessibility.updateShowSkipLink(false);
  }
}
